import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { saveAs } from 'file-saver'
import { getItem } from '@/../../shared/api/SharedClient'

export async function downloadProviderContract(id) {
    const contractBytes = await getItem(
        process.env.VUE_APP_PROVIDER_API_URL,
        `${API_CALL_URL_PATHS.providerContracts}/${id}/download`,
        false,
        'blob'
    )
    try {
        if (!contractBytes) return
        const mediaType = 'application/pdf'
        const blob = new Blob([contractBytes], { type: mediaType })

        const currentDate = new Date().toJSON().slice(0, 10)
        const filename = `Leverancierscontract-${id}-${currentDate}.pdf`

        saveAs(blob, filename)
    } catch (error) {
        console.error('Something went wrong while retrieving providers contract', error)
    }
}