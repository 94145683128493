<template>
    <div class="o-layout o-layout--large">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 qa-result-dashboard-title">
                    <title-text :title="PROVIDER.ResultsAndContractsPage.Title" />
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h3 class="mb-2 pl-4">{{ PROVIDER.ResultsAndContractsPage.ResultPageTitle }}</h3>

                    <recess-card variant="variant1">
                        <recess-button variant="secondary" :title="BUTTON_TEXT.historicalOrders" class="qa-historical-order-list-button" :url="{ name: 'historical-orders-overview' }" />
                    </recess-card>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h3 class="mb-2 pl-4">{{ PROVIDER.ResultsAndContractsPage.OrderFeesTitle }}</h3>

                    <recess-card variant="variant1">
                        <recess-button variant="secondary" :title="BUTTON_TEXT.viewDetails" class="qa-order-fees-details-button" :url="{ name: 'order-fees-overview' }" />
                    </recess-card>
                </div>

                <div class="col-12 mt-4">
                    <h3 class="mb-2 pl-4">{{ PROVIDER.ResultsAndContractsPage.ContractTitle }}</h3>

                    <recess-card variant="variant1">
                        <template v-if="!isAdministrator">
                            <div v-if="formattedContractExpiryDate" class="d-flex align-items-center">
                                <img alt="pdf icon" src="../../assets/images/PDF_icon.svg" class="mr-5" />
                                <!-- show contract end date -->
                                <h6 class="mr-5">{{ PROVIDER.ResultsAndContractsPage.ContractEndDateLabel }}</h6>
                                <h6 class="qa-provider-contract-end-date mr-5">
                                    {{ formattedContractExpiryDate }}
                                </h6>
                                <recess-button
                                    variant="tertiary"
                                    :title="downloadContractButtonLabel"
                                    class="qa-download-provider-contract-button"
                                    @click.native.prevent="triggerProviderContractDownload()"
                                />

                                <recess-button
                                    v-if="isTstEnvironment"
                                    variant="tertiary"
                                    :title="downloadContractButtonLabel"
                                    class="ml-3"
                                    url="https://confluence.valtech.com/display/EDUMS/Most+important+feature"
                                />
                            </div>
                            <div v-else>
                                <p class="qa-provider-no-contract-text">
                                    {{ PROVIDER.ResultsAndContractsPage.NoContractText }}
                                </p>
                            </div>

                            <div class="mt-4" v-if="isAccountManager">
                                <recess-button
                                    variant="secondary"
                                    :title="BUTTON_TEXT.viewPriceAgreements"
                                    class="mr-3 qa-price-agreement-details-button"
                                    :url="{ name: 'price-agreements-overview' }"
                                />

                                <recess-button variant="secondary" :title="BUTTON_TEXT.providerCostTypesPage" class="qa-cost-type-button" :url="{ name: 'provider-cost-types' }" />
                            </div>
                        </template>

                        <template v-else>
                            <p class="qa-admin-contract-text">
                                {{ PROVIDER.ResultsAndContractsPage.AdminContractText }}
                            </p>
                        </template>
                    </recess-card>
                </div>

                <div class="col-12 mt-4">
                    <h3 class="mb-2 pl-4">{{ PROVIDER.ResultsAndContractsPage.OrderTitle }}</h3>

                    <recess-card variant="variant1">
                        <recess-button variant="secondary" :title="ordersOverviewButtonLabel" class="qa-orders-list-button" :url="{ name: 'order-overview' }" />
                    </recess-card>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-12">
                    <div class="c-dashboard">
                        <div class="c-dashboard-item">
                            <h1 class="c-dashboard-title qa-total-orders-count">
                                {{ totalOrders }}
                            </h1>
                            <h6>Aangevraagd</h6>
                        </div>
                        <div class="c-dashboard-item">
                            <h1 class="c-dashboard-title u-color-warning qa-total-pending-provider-orders">
                                {{ totalPendingProviderOrders }}
                            </h1>

                            <h6>Bevestigd</h6>
                        </div>
                        <div class="c-dashboard-item">
                            <h1 class="c-dashboard-title u-color-success qa-total-invoiced-orders">
                                {{ totalInvoicedOrders }}
                            </h1>

                            <h6>Definitief</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import orderDashboardMixin from '@/mixins/orderDashboardMixin'
import { getItems, getItemById } from '@/../../shared/api/SharedClient'
import { downloadProviderContract } from '@/../../shared/utils/downloadFile'
import { formatToLocalDutchDate } from '@/utils/dateTimeHelper'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { BUTTON_TEXT, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import UserService from '../../../../shared/services/UserService'

export default {
    name: 'ResultDashboard',
    mixins: [orderDashboardMixin],
    props: {
        downloadContractButtonLabel: {
            type: String,
            default: 'Download (PDF)'
        }
    },
    data() {
        return {
            userService: new UserService(),
            isAdministrator: false,
            isAccountManager: false,
            BUTTON_TEXT,
            PROVIDER,
            providerContractsList: null,
            providerContractId: null,
            providerContractDetail: null,
            contractsListFilter: null,
            timeout: null,
            userProviderId: null,
            isTstEnvironment: process.env.NODE_ENV === 'test'
        }
    },
    async created() {
        this.isAdministrator = await this.userService.isAdministrator()
        this.isAccountManager = await this.userService.isAccountManager()
        this.userProviderId = await this.userService.getUserProviderId()
    },
    computed: {
        formattedContractExpiryDate() {
            return this.providerContractDetail && formatToLocalDutchDate(this.providerContractDetail.expiryDate)
        }
    },
    watch: {
        providerContractId: {
            handler() {
                this.getProviderContractDetails()
            }
        }
    },
    mounted() {
        // Code that will run only after the entire view has been rendered
        // We need this otherwise the token will not be sent in the request header through axios
        this.$nextTick(() => {
            this.setContractListsFilter()
            if (!this.isAdministrator) {
                this.getProviderContracts()
            }
        })
    },
    beforeDestroy() {
        clearTimeout(this.timeout)
    },
    methods: {
        setContractListsFilter() {
            if (this.userProviderId) {
                this.contractsListFilter = `providerId eq ${this.userProviderId}`
            } else {
                this.contractsListFilter = ''
            }
        },
        async triggerProviderContractDownload() {
            if (!this.providerContractDetail) return

            if (this.providerContractDetail !== null) {
                await downloadProviderContract(this.providerContractDetail.id)
            }
        },
        async getProviderContracts() {
            try {
                const response = await getItems(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.providerContracts, 1, 99999, null, this.contractsListFilter, false)
                if (!response || !response.items) return
                this.providerContractsList = response.items
                this.setProviderContractId()
            } catch (error) {
                console.error('Something went wrong while retrieving provider contracts', error)
            }
        },
        setProviderContractId() {
            this.providerContractId = this.providerContractsList && this.providerContractsList[0] && this.providerContractsList[0].id
        },
        async getProviderContractDetails() {
            if (!this.providerContractId || this.isAdministrator) return
            const response = await getItemById(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.providerContracts, this.providerContractId, false)
            try {
                if (!response) return
                this.providerContractDetail = response
            } catch (error) {
                console.error('Something went wrong while retrieving provider contract details', error)
            }
        }
    }
}
</script>
