import { mapState } from 'vuex'
import { getItemsFromSearchIndex } from "@/../../shared/api/SharedClient"
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import UserService from '../../../shared/services/UserService'
import TitleText from '@/components/atoms/TitleText'
import ButtonComponent from '@/components/atoms/ButtonComponent'

const orderDashboardMixin = {
    components: {
        TitleText,
        ButtonComponent
    },

    props: {
        ordersOverviewButtonLabel: {
            type: String,
            default: 'Alle orders'
        }
    },
    data() {
        return {
            userService: new UserService(),
            isAdministrator: false,
            API_CALL_URL_PATHS,
            totalOrders: 0,
            totalPendingProviderOrders: 0,
            totalInvoicedOrders: 0,
            orderCountFilter: {
                search: ''
            },
            orderCountPendingProviderFilter: {
                search: '',
                filter: ''
            },
            orderCountInvoicedFilter: {
                search: '',
                filter: ''
            },
            providerId: localStorage.getItem('providerId')
        }
    }, 
    computed: {
        ...mapState('providerModule', ['selectedProvider'])
    },
    watch: {
        'selectedProvider.id': {
            handler() {
                if (this.isAdministrator) {
                    this.providerId = this.selectedProvider.id
                    this.getCountFilters() 
                }
            }
        }
    }, 
    async mounted() {
        this.isAdministrator = await this.userService.isAdministrator()
        // Code that will run only after the
        // entire view has been rendered
        // We need this otherwise the token will not be sent in the request header through axios
        this.$nextTick(() => {
            this.getCountFilters()
        })
    },
    beforeDestroy() {
        this.resetState()
    },
    methods: {
        resetState() {
            this.totalOrders = 0
            this.totalPendingProviderOrders = 0
            this.totalInvoicedOrders = 0
        },
        getCountFilters() {
            this.setOrderCountFilter()
            this.getTotalOrders()
            this.setOrderCountPendingProviderFilter()
            this.getTotalPendingProviderOrders()
            this.setOrderCountInvoicedFilter()
            this.getTotalInvoicedOrders()
        },


        setOrderCountFilter() {
            // Is admin and has the provider selected
            if (this.isAdministrator && this.providerId) {
                this.orderCountFilter.filter = `providerId eq '${this.providerId}'`
            }
            // Is a provider so he can't see Invalid orders
            else if (!this.isAdministrator) { 
                this.orderCountFilter.filter = "status ne 'Invalid'"
            } else {
                this.orderCountFilter.filter = ''
            }
        },
        setOrderCountPendingProviderFilter() {
            let selectedProviderId = ''

            if (this.selectedProvider && this.providerId) {
                selectedProviderId = `providerId eq '${this.providerId}'`
            }

            if (selectedProviderId !== '') {
                this.orderCountPendingProviderFilter.filter = selectedProviderId.concat(' and ',"status eq 'PendingProvider'")
            } else {
                this.orderCountPendingProviderFilter.filter = "status eq 'PendingProvider'"
            }
        },
        setOrderCountInvoicedFilter() {
            let selectedProviderId = ''
            const orderStatusQuery = "(status eq 'Invoiced' or status eq 'PendingInvoice')"

            if (this.selectedProvider && this.providerId) {
                selectedProviderId = `providerId eq '${this.providerId}'`
            }

            if (selectedProviderId !== '') {
                this.orderCountInvoicedFilter.filter = selectedProviderId.concat(' and ', orderStatusQuery)
            } else {
                this.orderCountInvoicedFilter.filter = orderStatusQuery
            }
        },



        async getTotalOrders() {
            try {
                const response = await getItemsFromSearchIndex(
                    process.env.VUE_APP_ORDER_API_URL,
                    API_CALL_URL_PATHS.orders,
                    API_CALL_URL_PATHS.search,
                    this.orderCountFilter
                )
                if (!response) return
                this.totalOrders = response.count
            } catch (error) {
                console.error('Something went wrong while retrieving orders', error)
            }
        },
        async getTotalPendingProviderOrders() {
            try {
                const response = await getItemsFromSearchIndex(
                    process.env.VUE_APP_ORDER_API_URL,
                    API_CALL_URL_PATHS.orders,
                    API_CALL_URL_PATHS.search,
                    this.orderCountPendingProviderFilter
                )
                if (!response) return
                this.totalPendingProviderOrders = response.count
            } catch (error) {
                console.error('Something went wrong while retrieving orders', error)
            }
        },
        async getTotalInvoicedOrders() {
            try {
                const response = await getItemsFromSearchIndex(
                    process.env.VUE_APP_ORDER_API_URL,
                    API_CALL_URL_PATHS.orders,
                    API_CALL_URL_PATHS.search,
                    this.orderCountInvoicedFilter
                )
                if (!response) return
                this.totalInvoicedOrders = response.count
            } catch (error) {
                console.error('Something went wrong while retrieving orders', error)
            }
        }
    }
}

export default orderDashboardMixin
